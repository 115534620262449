import React, { useEffect, useMemo, useRef, useState, memo } from "react";
// import Header2 from "../../../component/sidebar/Header2";
// import Sidebar from "../../../component/sidebar/Sidebar";
import { useDispatch, useSelector } from "react-redux";
// import { allapiAction } from "@/Redux/common/action";
import moment from "moment";
import DatePicker from "react-datepicker";
import { allapiAction } from "../../Redux/common/action";
import Sidebar from "../Layout/Sidebar";
import HeaderAccount from "../Layout/HeaderAccount";
import { toast } from "react-toastify";

function MonthReport() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(new Date());
  const [startDate2, setStartDate2] = useState(new Date());
  const [startDate2vs, setStartDate2vs] = useState("");
  const [startDate2vse, setStartDate2vse] = useState("");
  const seller_date_reports = useSelector((state) =>
    state?.allapi?.seller_date_reports ? state?.allapi?.seller_date_reports : {}
  );
  const get_buss_pro_1 = useSelector((state) =>
    state?.allapi?.get_buss_pro_1 ? state?.allapi?.get_buss_pro_1 : {}
  );
  console.log(startDate);
  console.log(startDate2vs);
  console.log(get_buss_pro_1);
  console.log(get_buss_pro_1?.referId);
  //   const date = new Date(startDate2vs);
  //   console.log(date);
  //   date.setUTCHours(0, 0, 0, 0);

  // // Log the date to verify
  // console.log(date.toISOString());
  const deshboard = useSelector((state) =>
    state?.allapi?.deshboard_state ? state?.allapi?.deshboard_state : {}
  );

  console.log(seller_date_reports);
  console.log(deshboard);

  useEffect(() => {
    // dispatch(allapiAction.getsellerprofilebyseller({}));
    dispatch(allapiAction.getuserBussnissMonth({}));
    return () => {};
  }, []);

  const hendleToChackReport = () => {
    if (!startDate2vs) {
      toast.error("please select start date");
      return;
    }
    if (!startDate2vse) {
      toast.error("please select end date");
      return;
    }
    dispatch(
      allapiAction.sellerDateWiseReport({
        startDate: startDate2vs,
        endDate: startDate2vse,
      })
    );
    return () => {};
  };
  const range = (start, end, step = 1) => {
    const array = [];
    for (let i = start; i < end; i += step) {
      array.push(i);
    }
    return array;
  };

  const years = range(1990, new Date().getFullYear() + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div>
      <Sidebar />

      <main className="main-wrap">
        <HeaderAccount />

        <section className="content-main">
          <div className="content-header">
            <div>
              <h2 className="content-title card-title">Month Report</h2>
            </div>
            <div></div>
          </div>

          <div className="card mb-4">
            <div class="row">
              <div class="col-md-12">
                <div
                  class="widget widget-categories"
                  style={{ paddingBottom: "25px", minHeight: "70vh" }}
                >
                  <div className="row profile_ul dtpick">
                    {/* <div className="col-4 mt-3">
                      <DatePicker
                        selected={startDate}
                        showIcon
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="dd/mm/yyyy"
                        onChange={(date) => setStartDate(date)}
                      />
                    </div>
                    <div className="col-4 mt-3">
                      <DatePicker
                        selected={startDate2}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="dd/mm/yyyy"
                        onChange={(date) => setStartDate2(date)}
                      />
                    </div> */}
                    <div class="col-sm-4">
                      <div className="input-field">
                        <i className="fas fa-calendar-check-o"></i>
                        <input
                          type="date"
                          required=""
                          name="dob"
                          placeholder="Enter dob"
                          onChange={(e) => {
                            setStartDate2vs(e?.target?.value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div className="input-field">
                        <i className="fas fa-calendar-check-o"></i>
                        <input
                          type="date"
                          required=""
                          name="dob"
                          placeholder="Enter dob"
                          onChange={(e) => {
                            setStartDate2vse(e?.target?.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-4">
                      <div
                        onClick={() => {
                          hendleToChackReport();
                        }}
                        className="btn btn-sm font-sm btn-light rounded mt-3"
                      >
                        Submit
                      </div>
                    </div>
                    <div className="col-12 mt-5">
                      <p className="text-success">
                        SALE FROM{" "}
                        {seller_date_reports?.startDate &&
                          moment(seller_date_reports?.startDate).format(
                            " DD MMM yyyy "
                          )}{" "}
                        -{" "}
                        {seller_date_reports?.startDate &&
                          moment(seller_date_reports?.endDate).format(
                            " DD MMM yyyy "
                          )}
                        : ₹ {seller_date_reports?.amount2}
                      </p>
                      {/* <p className="text-success">
                        SALE FROM{" "}
                        {seller_date_reports?.startDate &&
                          moment(seller_date_reports?.startDate).format(
                            " DD MMM yyyy "
                          )}{" "}
                        -{" "}
                        {seller_date_reports?.startDate &&
                          moment(seller_date_reports?.endDate).format(
                            " DD MMM yyyy "
                          )}
                        : ₹ {seller_date_reports?.amount2}
                      </p> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6">
                      <ul class="profile_ul mtrp">
                        <li>
                          <a href="#">
                            {deshboard &&
                              moment(deshboard?.buss?.curM?.month).format(
                                "MMM yyyy"
                              )}
                            <b>
                              <i class="fa fa-inr"> </i>
                              {deshboard?.buss?.curM?.amount}
                            </b>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            {deshboard &&
                              moment(deshboard?.buss?.lastM?.month).format(
                                "MMM yyyy"
                              )}
                            :
                            <b>
                              <i class="fa fa-inr"> </i>
                              {deshboard?.buss?.lastM?.amount}
                            </b>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            {deshboard &&
                              moment(deshboard?.buss?.lastM2?.month).format(
                                "MMM yyyy"
                              )}
                            :
                            <b>
                              <i class="fa fa-inr"> </i>
                              {deshboard?.buss?.lastM2?.amount}
                            </b>
                          </a>
                        </li>

                        {
                          <li>
                            <a href="#">
                              {deshboard &&
                                moment(deshboard?.buss?.lastM3?.month).format(
                                  "MMM yyyy"
                                )}{" "}
                              :
                              <b>
                                <i class="fa fa-inr"> </i>
                                {deshboard?.buss?.lastM3?.amount}
                              </b>
                            </a>
                          </li>
                        }

                        {get_buss_pro_1?.referId == "RIL00001" ? (
                          <li>
                            {" "}
                            <a href="#">
                              Apr 2024 :
                              <b>
                                <i class="fa fa-inr"> </i>
                                17663700
                              </b>
                            </a>
                          </li>
                        ) : get_buss_pro_1?.referId == "RIL28262" ? (
                          <li>
                            {" "}
                            <a href="#">
                              Apr 2024 :
                              <b>
                                <i class="fa fa-inr"> </i>
                                13199152
                              </b>
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a href="#">
                              {deshboard &&
                                moment(deshboard?.buss?.lastM4?.month).format(
                                  "MMM yyyy"
                                )}{" "}
                              :
                              <b>
                                <i class="fa fa-inr"> </i>
                                {deshboard?.buss?.lastM4?.amount}
                              </b>
                            </a>
                          </li>
                        )}

                        {get_buss_pro_1?.referId == "RIL00001" ? (
                          <li>
                            {" "}
                            <a href="#">
                              Mar 2024 :
                              <b>
                                <i class="fa fa-inr"> </i>
                                {/* 19091951 */}
                                19027443
                              </b>
                            </a>
                          </li>
                        ) : get_buss_pro_1?.referId == "RIL28262" ? (
                          <li>
                            {" "}
                            <a href="#">
                              Mar 2024 :
                              <b>
                                <i class="fa fa-inr"> </i>
                                14793105
                              </b>
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a href="#">
                              {deshboard &&
                                moment(deshboard?.buss?.lastM5?.month).format(
                                  "MMM yyyy"
                                )}{" "}
                              :
                              <b>
                                <i class="fa fa-inr"> </i>
                                {deshboard?.buss?.lastM5?.amount}
                              </b>
                            </a>
                          </li>
                        )}
                        {get_buss_pro_1?.referId == "RIL00001" ? (
                          <li>
                            {" "}
                            <a href="#">
                              Feb 2024 :
                              <b>
                                <i class="fa fa-inr"> </i>
                                16716089
                              </b>
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a href="#">
                              {deshboard &&
                                moment(deshboard?.buss?.lastM6?.month).format(
                                  "MMM yyyy"
                                )}{" "}
                              :
                              <b>
                                <i class="fa fa-inr"> </i>
                                {deshboard?.buss?.lastM6?.amount}
                              </b>
                            </a>
                          </li>
                        )}
                        {/* {get_buss_pro_1?.referId == "RIL00001" ? (
                          <li>
                            {" "}
                            <a href="#">
                              Jan 2024 :
                              <b>
                                <i class="fa fa-inr"> </i>
                                17872818
                              </b>
                            </a>
                          </li>
                        ) : get_buss_pro_1?.referId == "RIL28262" ? (
                          <li>
                            {" "}
                            <a href="#">
                              Jan 2024 :
                              <b>
                                <i class="fa fa-inr"> </i>
                                13037422
                              </b>
                            </a>
                          </li>
                        ) : (
                          <li>
                            <a href="#">
                              {deshboard &&
                                moment(deshboard?.buss?.lastM6?.month).format(
                                  "MMM yyyy"
                                )}{" "}
                              :
                              <b>
                                <i class="fa fa-inr"> </i>
                                {deshboard?.buss?.lastM6?.amount}
                              </b>
                            </a>
                          </li>
                        )} */}

                        {/*                         
                        {get_buss_pro_1?.referId == "RIL28262" && (
                          <li>
                            {" "}
                            <a href="#">
                              Mar 2024 :
                              <b>
                                <i class="fa fa-inr"> </i>
                                14793105
                              </b>
                            </a>
                          </li>
                        )} */}
                        {/* {get_buss_pro_1?.referId == "RIL28262" && (
                          <li>
                            {" "}
                            <a href="#">
                              Fab 2024 :
                              <b>
                                <i class="fa fa-inr"> </i>
                                3113315
                              </b>
                            </a>
                          </li>
                        )} */}
                        {/* {get_buss_pro_1?.referId == "RIL28262" && (
                          <li>
                            {" "}
                            <a href="#">
                              Jan 2024 :
                              <b>
                                <i class="fa fa-inr"> </i>
                                13037422
                              </b>
                            </a>
                          </li>
                        )} */}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default MonthReport;
